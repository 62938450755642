'use client'; // Error components must be Client Components
import React, { useEffect } from 'react';
import { Alert, Flex } from '@mantine/core';
import * as Sentry from '@sentry/nextjs';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    // Log the error to an error reporting service
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <Flex h="50vh" justify="center" align="center">
      <Alert color="red" title="Looks like something went wrong!">
        {error.message}
      </Alert>
    </Flex>
  );
}
